import axios from 'axios'
import moment from 'moment'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionService from '@services/fetch_stats_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_shares',
  attributes: [
    'id',
    'hashid',
    'event_id',
    'parent_id',
    'promoter_id',
    'promoter_name',
    'promoter_email',
    'description',
    'is_enabled',
    'token',
    'depth',
    'lft',
    'rgt',
    'share_product_ships',
    'is_suspended',
    'suspended_at',
    'is_template',
    'sign_up_token',
    'top_product_ids',
    'is_meta_noindex',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'is_allowed_for_campaign_invitation',
    'approved_admin_id',
    'member_info',
    'slug',
    'current_slug',
    'created_at'
  ],
  editableAttributes: [
    'event_id',
    'promoter_id',
    'parent_id',
    'is_suspended',
    'share_product_ships',
    'description',
    'uploaded_attachment_ids',
    'top_product_ids',
    'is_meta_noindex',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'slug'
  ]
}

export default class PromoterShare extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static findWithoutProducts(id) {
    return axios.get(`${new this().apiBasePath()}/${id}?skip_products=1`)
  }

  storeFrontUrl() {
    return `${window.location.origin}/me/${this.current_slug}`
  }

  checkOrderLink() {
    return `/me/${this.current_slug}/check_order`
  }

  static fetchDashboardData(dataType, options) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')
    return axios.get(
      `${new this().apiBasePath()}/dashboard/${dataType}?${optionsQueryString}`
    )
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  toggleIsAllowedForCampaignInvitation() {
    return axios.put(
      `${this.apiBasePath()}/${
        this.id
      }/toggle_is_allowed_for_campaign_invitation`
    )
  }

  revokeToken() {
    return axios.post(`${this.apiBasePath()}/${this.id}/revoke_token`)
  }

  updateInfo() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/info`,
      this.requestBody()
    )
  }

  updateSeo() {
    return axios.put(`${this.apiBasePath()}/${this.id}/seo`, this.requestBody())
  }

  updateTopProductIds() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/top_product_ids`,
      this.requestBody()
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  static fetchModelStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/model_stats?${queryString}`)
  }

  static fetchBriefMemberInfo(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}/brief_member_infos?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  // orders
  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchOrderStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${this.id}/orders/stats?${queryString}`
    )
  }

  checkOrder(options) {
    const requestBody = {
      data: {
        type: 'promoter-share-check-order',
        attributes: {
          phone: options.phone,
          email: options.email
        }
      }
    }
    return axios.post(
      `${this.apiBasePath()}/${this.id}/check_order`,
      requestBody
    )
  }

  // descendants
  fetchDescendants() {
    return axios.get(`${this.apiBasePath()}/${this.id}/descendants`)
  }

  // products
  fetchProducts(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    if (options.share_product_ship_is_available)
      query += '&share_product_ship_is_available=1'

    return axios.get(`${this.apiBasePath()}/${this.id}/products?${query}`)
  }

  fetchTopProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/top_products`)
  }

  /**
   * 將此 promoter 停權
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  suspend(options) {
    const requestBody = {
      data: {
        type: 'promoter-share-suspend',
        attributes: {
          include_descendants: options.include_descendants
        }
      }
    }
    return axios.put(`${this.apiBasePath()}/${this.id}/suspend`, requestBody)
  }

  approveToJoinKolcenter() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/approve_to_join_kolcenter`
    )
  }

  shareVariantShips() {
    return this.share_product_ships
      .map((shareProductShip) => {
        return shareProductShip.share_variant_ships
      })
      .flat()
  }

  static uploadAttachments(formData) {
    return axios.post(
      `${new this().apiBasePath({
        withResourceType: false
      })}/editor_attachments`,
      formData
    )
  }

  fetchShareProductShips(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/share_product_ships?${query}`
    )
  }

  fetchShareProductShipsV2(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/share_product_ships/v2?${query}`
    )
  }

  fetchBriefShareProductShips(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/brief_share_product_ships?${query}`
    )
  }

  fetchEventProductShips(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(
      `${this.apiBasePath()}/${this.id}/event_product_ships?${query}`
    )
  }

  toggleShareProductShip({ eventProductShipId }) {
    const requestBody = {
      data: {
        type: 'promoter-share-check-order',
        attributes: {
          event_product_ship_id: eventProductShipId
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/toggle_share_product_ship`,
      requestBody
    )
  }

  fetchShareLevels(options = {}) {
    let query = FetchingDataOptionsService.call(options)

    return axios.get(`${this.apiBasePath()}/${this.id}/share_levels?${query}`)
  }

  updateShareLevel(promoterShareLevel) {
    const requestBody = {
      data: {
        type: 'promoter-share-update-promoter-level',
        attributes: promoterShareLevel.attributes()
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/share_levels/${promoterShareLevel.id}`,
      requestBody
    )
  }

  // campaign main groups

  fetchCampaignMainGroups(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/campaign_main_groups?${FetchingDataOptionsService.call(options)}`
    )
  }

  // campaign share ships

  fetchCampaignShareShips(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/campaign_share_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  createPreferredCategoryShipWithProducts({ preferredCategoryIds }) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/preferred_category_ship_with_products`,
      {
        data: {
          type: 'promoter-share-preferred-category-ship-with-products',
          attributes: {
            preferred_category_ids: preferredCategoryIds
          }
        }
      }
    )
  }

  // group share ships

  fetchGroupShareShips(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/group_share_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  getGroupShareShip(groupId) {
    return axios.get(
      `${this.apiBasePath()}/${this.id}/group_share_ships?group_id=${groupId}`
    )
  }

  // withdrawal request
  fetchWithdrawalRequests(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/withdrawal_requests?${FetchingDataOptionsService.call(options)}`
    )
  }

  createWithdrawalTransactions({ request_ids }) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/withdrawal_transactions`,
      {
        data: {
          type: 'create-withdrawal-transaction',
          attributes: {
            request_ids: request_ids
          }
        }
      }
    )
  }

  payWithdrawalRequest({ withdrawalRequest }) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/withdrawal_requests/${
        withdrawalRequest.id
      }/pay`
    )
  }

  // api for store-front
  fetchStoreFrontCampaigns(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/store_front_campaigns?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchStoreFrontProductCategories(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/store_front_product_categories?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }
}
