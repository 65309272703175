import * as types from '../mutation-types'

export const fetchMediaAccounts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchMediaAccounts')

  return new Promise((resolve, reject) => {
    model
      .fetchMediaAccounts(options)
      .then((response) => {
        dispatch(
          'promoterMediaAccounts/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterMediaServices/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchMediaAccounts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchMediaAccounts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveMediaAccount = (
  { dispatch, commit },
  { model, mediaAccount }
) => {
  commit(types.API_REQUEST_START, 'createMediaAccount')

  return new Promise((resolve, reject) => {
    model
      .saveMediaAccount(mediaAccount)
      .then((response) => {
        dispatch(
          'promoterMediaAccounts/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'saveMediaAccount')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveMediaAccount,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              mediaAccount
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyMediaAccount = (
  { dispatch, commit },
  { model, mediaAccountId }
) => {
  commit(types.API_REQUEST_START, 'destroyMediaAccount')

  return new Promise((resolve, reject) => {
    model
      .destroyMediaAccount(mediaAccountId)
      .then((response) => {
        dispatch(
          'promoterMediaAccounts/destroyResourcesFromRelationships',
          mediaAccountId,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'destroyMediaAccount')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyMediaAccount,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              mediaAccountId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
