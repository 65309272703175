import * as types from '../mutation-types'

export const fetchOrderStats = (
  { dispatch, commit },
  { model, statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchOrderStats')

  return new Promise((resolve, reject) => {
    model
      .fetchOrderStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrderStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchOrders = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'fetchOrders')

  return new Promise((resolve, reject) => {
    model
      .fetchOrders(options)
      .then((response) => {
        dispatch(
          'orders/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOrders,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const checkOrder = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'checkOrder')

  return new Promise((resolve, reject) => {
    model
      .checkOrder(options)
      .then((response) => {
        dispatch(
          'orders/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchOrders')

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: checkOrder,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
